import AirportShuttleIcon from "@mui/icons-material/AirportShuttle";
import FreeBreakfastIcon from "@mui/icons-material/FreeBreakfast";
import InventoryIcon from "@mui/icons-material/Inventory";
import LiveTvIcon from "@mui/icons-material/LiveTv";
import LocalHotelIcon from "@mui/icons-material/LocalHotel";
import NetworkWifiIcon from "@mui/icons-material/NetworkWifi";
import ShowerIcon from "@mui/icons-material/Shower";
import TempleBuddhistIcon from "@mui/icons-material/TempleBuddhist";
import {
  BalconyView,
  BishnuTemple,
  KingBedEight,
  KingBedFour,
  KingBedOne,
  KingBedThree,
  KingBedTwo,
  Lobby,
  OutSidePhoto,
  OutSidePhotoSide,
  RoomService,
  Room_one,
  Stupa,
  TwinBedRoomFive,
  TwinBedRoomOne,
  TwinBedRoomTwo,
  bathroomGel,
  bathroomMain,
  bathroomShower,
  bathroomShowerOne,
  bathroomShowerSoap,
  bathroomShowerTwo,
  iskNepal,
  room_two,
  shivapuriNationalPark,
} from "src/assets";

export const reviewImages = [
  Room_one,
  room_two,
  OutSidePhoto,
  OutSidePhotoSide,
];

export const eventsData = [
  {
    eventTitle: "Budhanilkantha: Hike or Ride",
    eventSubTitle: "Jamchen Vijaya Stupa",
    eventDescription:
      "Jamchen Vijaya Stupa is a newly built beautiful monument on the top of Budhanilkantha Hill. From the top, you can see the panoramic view of Kathmandu Valley.The stupa is open for visitors from 8:00 A.M – 12:00 PM and 2:00 PM to 5:00 P.M.  The path to reach there is a bit difficult, but the final destination will make you feel worth it.",
    eventDate: "8 AM to 5 PM open everyday",
    imageItem: Stupa,
    isEvent: true,
    eventDescriptionDetail: [
      "Jamchen Vijaya Stupa is a newly built beautiful monument on the top of Budhanilkantha Hill. From the top, you can see the panoramic view of Kathmandu Valley.",
      "The stupa is open for visitors from 8:00 A.M – 12:00 PM and 2:00 PM to 5:00 P.M.  The path to reach there is a bit difficult, but the final destination will make you feel worth it.",
      "The view from the top, clean air and serene environment will make you to stay and spend your time as much as possible.",
      "Not only the environment outside, but also the environment inside the stupa is magical. Peace with melodious chanting music is really marvellous and it’ll compel you to meditate there for a while.",
      "After being there, you’ll feel like you should have known this place earlier. ",
      "You’ll return from the place with a promise that you’ll come again with your loved ones and also tell others about this place, and why should they visit?",
    ],
    eventAuthor: "Vijay Thapa",
    location: (
      <iframe
        title="stupa"
        src="https://www.google.com/maps/embed?pb=!1m26!1m12!1m3!1d7059.465141268814!2d85.35674888360619!3d27.7872131929154!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m11!3e0!4m3!3m2!1d27.781192299999997!2d85.35213929999999!4m5!1s0x39eb1dcd4a72fb3b%3A0x53bac5f9708b0f76!2sJamchen%20Vijaya%20Stupa%2C%20Budhanilkantha!3m2!1d27.790795499999998!2d85.3614182!5e0!3m2!1sen!2snp!4v1701500389177!5m2!1sen!2snp"
        width="100%"
        height="450"
        loading="lazy"
      ></iframe>
    ),
  },
  {
    eventTitle: "Budhanilkantha, Kathmandu, Nepal",
    eventSubTitle: "Budhanilkantha Temple",
    eventDescription:
      "The largest stone statue of Lord Vishnu in Nepal, reclining on a bed of snakes in the middle of a pond, is located at the foot of the Shivpuri hills in the northern-most portion of the Kathmandu Valley",
    eventDate: "5 AM to 8 PM open everyday",
    imageItem: BishnuTemple,
    isEvent: false,
    eventDescriptionDetail: [
      "The largest stone statue of Lord Vishnu in Nepal, reclining on a bed of snakes in the middle of a pond, is located at the foot of the Shivpuri hills in the northern-most portion of the Kathmandu Valley. The Lichchhavi period is represented by a five-meter-long granite picture carved out of a single rock. According to legend, a farmer was ploughing his field one day when his plough hit a boulder, and blood began pouring out of the cut in the stone, much to his amazement and fear.",
      "He discovered the wonderful image of the reclining Vishnu that had been hidden in the dirt while digging around the massive boulder. On the auspicious two main Ekadashis, Harishayani and Haribodhini, a large mela (fair) is conducted at Budhanilkantha, marking the four-month period when the Lord retires to sleep.",
      "Though the temple is called Budhanilkantha, it is not named after the Buddha; instead, Budhanilkantha means “Old Blue Throat.” The statue represents Lord Vishnu, who, along with Brahma and Shiva, is considered one of the ‘Trimurtis.’",
      "Black basalt is used to create the main statue. The statue is 5 metres tall (about 16.4 feet) and stands in the centre of a 13-meter-long (42.65-foot) sunken pool of water. In his four hands, he holds the Sudarshana Chakra, a Club, a Conch Shell, and a jewel. He wears a crown with various Kirtimukha figures carved on it, which is frequently overlapping by a silver crown. This temple is recognised as a sacred site for Hindus, but it is also revered by Buddhists, demonstrating religious harmony in Nepal.",
      "The Budhanilkantha Temple has become the site where Haribondhini Ekadashi Mela takes place on the 11th day of the Hindu month of Kartika (October–November). According to one story, a farmer and his wife once struck a figure while plowing the field, which caused it to start soaking blood into the ground. It was suggested for many years that the statue floats in the pool. Indeed, limited access to scientific rigour in 1957 failed to confirm or refute the claim but a small chip of the statue did confirm it to be silica-based stone but with a remarkably low density similar to lava rock. The Floating statue continues to fascinate and the number of subsequent requests for access to study its physical nature have been declined.",
    ],
    eventAuthor: "Bishnu Hari Sapkota",
    location: (
      <iframe
        title="Budhanilkantha Temple"
        src="https://www.google.com/maps/embed?pb=!1m26!1m12!1m3!1d1764.973142316987!2d85.35327271691632!3d27.780629226326663!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m11!3e2!4m3!3m2!1d27.781146399999997!2d85.3521341!4m5!1s0x39eb1c226224709b%3A0x45a65134a18bfef1!2sBudhanilkantha%20Temple%2C%20Budhanilkantha%20temple%2C%20Budhanilkantha!3m2!1d27.777836699999998!2d85.3620789!5e0!3m2!1sen!2snp!4v1701501115373!5m2!1sen!2snp"
        width="100%"
        height="450"
        loading="lazy"
      ></iframe>
    ),
  },
  {
    eventTitle: "Budhanilkantha, Kathmandu, Nepal",
    eventSubTitle: "ISKCON Nepal",
    eventDescription:
      "The International Society for Krishna Consciousness (ISKCON), otherwise known as the Hare Krishna movement, includes five hundred major centers, temples and rural communities, nearly one hundred affiliated vegetarian restaurants, thousands of nāmahāttas or local meeting groups",
    eventDate: "4:30 AM onwards",
    imageItem: iskNepal,
    isEvent: true,
    eventDescriptionDetail: [
      "The International Society for Krishna Consciousness (ISKCON), otherwise known as the Hare Krishna movement, includes five hundred major centers, temples and rural communities, nearly one hundred affiliated vegetarian restaurants, thousands of nāmahāttas or local meeting groups, a wide variety of community projects, and millions of congregational members worldwide. Although less than fifty years on the global stage, ISKCON has expanded widely since its founding by His Divine Grace A. C. Bhaktivedanta Swami Prabhupāda in New York City in 1966.",
      "ISKCON belongs to the Gaudiyā-Vaishnava sampradāya, a monotheistic tradition within the Vedic or Hindu culture. Philosophically it is based on the Sanskrit texts Bhagavad-gītā and the Bhagavat Purana, or Srimad Bhāgavatam. These are the historic texts of the devotional bhakti yoga tradition, which teaches that the ultimate goal for all living beings is to reawaken their love for God, or Lord Krishna, the “all-attractive one”.",
      "God is known across the world by many names including Allah, Jehovah, Yahweh, Rama, etc. ISKCON devotees chant God’s names in the form of the mahā-mantra, or the great prayer for deliverance: Hare Krishna, Hare Krishna, Krishna Krishna, Hare Hare/Hare Rama Hare Rama, Rama Rama, Hare Hare.",
      "Many leading academics have highlighted ISKCON’s authenticity. Diana Eck, Professor of Comparative Religion and Indian Studies at Harvard University, describes the movement as “a tradition that commands a respected place in the religious life of humankind.” In the 1980s Dr. A. L. Basham, one of the world’s authorities on Indian history and culture, wrote of ISKCON that, “It arose out of next to nothing in less than twenty years and has become known all over the West. This, I feel, is a sign of the times and an important fact in the history of the Western world.",
      "What is ISKCONISKCON’s founder, Srila Prabhupada, has drawn appreciation from scholars and religious leaders alike for his remarkable achievement in presenting India’s Vaishnava spiritual culture in a relevant manner to contemporary Western and worldwide audiences.",
      "Members of ISKCON practice bhakti-yoga in their homes and also worship in temples. They also promote bhakti-yoga, or Krishna Consciousness, through festivals, the performing arts, yoga seminars, public chanting, and the distribution of the society’s literatures. ISKCON members have also opened hospitals, schools, colleges, eco-villages, free food distribution projects, and other institutions as a practical application of the path of devotional yoga.",
    ],
    eventAuthor: "ISKCON Nepal",
    location: (
      <iframe
        title="ISKCON Temple"
        src="https://www.google.com/maps/embed?pb=!1m26!1m12!1m3!1d3529.859706524753!2d85.3545237339158!3d27.783296643338286!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m11!3e2!4m3!3m2!1d27.781146399999997!2d85.3521341!4m5!1s0x39eb1c2724e6919f%3A0xa4b659573774c3f4!2sISKCON%2C%20Budhanilkantha%20School%20Main%20Road%2C%20Budhanilkantha!3m2!1d27.7836549!2d85.3569996!5e0!3m2!1sen!2snp!4v1701501304998!5m2!1sen!2snp"
        width="100%"
        height="450"
        loading="lazy"
      ></iframe>
    ),
  },
  {
    eventTitle: "Budhanilkantha: Hike or Ride",
    eventSubTitle: "Shivapuri National Park Hike",
    eventDescription:
      "The northern part of Kathmandu soars to the sprawling dense forests of Shivapuri Nagarjun National Park. The region was upgraded to a national park status in 2002 in order to protect the Kathmandu Valley’s main water source, as well as 177 stunning species of birds",
    eventDate: "5:00 AM to 6:00 PM Everyday",
    imageItem: shivapuriNationalPark,
    isEvent: false,
    eventDescriptionDetail: [
      "The northern part of Kathmandu soars to the sprawling dense forests of Shivapuri Nagarjun National Park. The region was upgraded to a national park status in 2002 in order to protect the Kathmandu Valley’s main water source, as well as 177 stunning species of birds and a variety of rare orchids. This region is also one of the few remaining primary woodlands left in the valley. The forests of Shivapuri Nagarjun National Park are alive with lots of monkeys, and maybe even bears and leopards!",
      "Shivapuri Nagarjun National Park is perfect choice for individuals looking for an easy trek around close vicinity of Kathmandu. The trek is relatively easy, but a gratifying and rich experience as we will also get the chance to explore Shivapuri watershed and conservation area along with other perks. We recommend this one-day trek to those who do not have much time or energy to allocate but are looking for a refreshing getaway with a hint of adventure.",
      "The national park is split into two distinct regions by the roads running north from the capital. The majority of the park is located directly north of Budanilkantha, on the trekking trail to Helambu, but there is also a second forested zone called Rani Ban, uphill from Balaku on road towards the beautiful Trishuli Bazaar, encircling the 2,095m Nagarjun Hill.",
      "If you start trek at around 7 am, you will have enough time in the evening to relax after a hard day’s trek.",
    ],
    eventAuthor: "Himalayan News",
    location: (
      <iframe
        title="National Park Ticket Counter"
        src="https://www.google.com/maps/embed?pb=!1m26!1m12!1m3!1d7057.83336877045!2d85.32011205583963!3d27.81233531219416!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m11!3e2!4m3!3m2!1d27.781146399999997!2d85.3521341!4m5!1s0x39eb1e56a339887d%3A0x633eb8539ea1fc60!2sNational%20Park%20ticket%20counter%2C%20F082%2C%20Tarakeshwar!3m2!1d27.813813!2d85.3179143!5e0!3m2!1sen!2snp!4v1701501546748!5m2!1sen!2snp"
        width="100%"
        height="450"
        loading="lazy"
      ></iframe>
    ),
  },
];

export const whyUsData = [
  {
    icon: (
      <NetworkWifiIcon
        style={{
          color: "#2D9596",
          fontSize: 24,
        }}
      />
    ),
    title: "100MBPS, Dual Router: Seamless Connection 🚀",
    describe:
      "Enjoy seamless internet with 100MBPS speed and a dual router setup! No issues—your connection is fast and reliable. 🚀 #SmoothInternet",
  },
  {
    icon: (
      <ShowerIcon
        style={{
          color: "#2D9596",
          fontSize: 24,
        }}
      />
    ),
    title: "Invigorating Hot Showers, Anytime Bliss.",
    describe:
      "Experience ultimate comfort with 24/7 hot water, offering one of the best showers with invigorating force. Elevate your stay with our indulgent amenities. 🚿✨",
  },
  {
    icon: (
      <LocalHotelIcon
        style={{
          color: "#2D9596",
          fontSize: 24,
        }}
      />
    ),
    title: "Tranquil Nights, Serene Heights",
    describe:
      "Enjoy peaceful sleep at our sacred  trails. No bus noise, serene surroundings ensure a tranquil night's rest. 🌙😴 #GoodSleep",
  },
  {
    icon: (
      <AirportShuttleIcon
        style={{
          color: "#2D9596",
          fontSize: 24,
        }}
      />
    ),
    title: "Effortless Travel, Quick Access, Delightful Dining",
    describe:
      "Effortless travel! Quick access to taxis and local buses ensures convenient journeys. Discover good and clear nearby restaurants for a delightful experience. 🚕🚌🍽️ #EasyTransportation #GreatEats",
  },
  {
    icon: (
      <FreeBreakfastIcon
        style={{
          color: "#2D9596",
          fontSize: 24,
        }}
      />
    ),
    title: "Start Your Day with Indulgence!",
    describe:
      "Savor a delightful breakfast on us! Enjoy a complimentary morning feast with choices like muesli with fruit, porridge, and the exclusive Sacred Trails Special Breakfast. Your day starts with a delightful array of options! 🍽️🌅 #YummyBreakfast",
  },
  {
    icon: (
      <TempleBuddhistIcon
        style={{
          color: "#2D9596",
          fontSize: 24,
        }}
      />
    ),
    title: "Serenity, Temples, Hiking: Tranquil Stays",
    describe:
      "Discover spiritual gems during your stay! Visit the Sleeping Vishnu Temple, serene stupas, and enjoy hiking adventures nearby. Immerse yourself in cultural and natural wonders. 🏛️🚶‍♂️ #TempleExploration #HikingAdventures",
  },
];

export const RoomDetailsData = [
  {
    id: 2,
    roomName: "Twin Bed Room",
    roomSlogan: "Where the Hospitality of Kathmandu Lives",
    mainImage: TwinBedRoomFive,
    subImage: bathroomMain,
    subImageOne: RoomService,
    allImages: [
      TwinBedRoomFive,
      TwinBedRoomOne,
      TwinBedRoomTwo,
      TwinBedRoomFive,
      bathroomMain,
      bathroomGel,
      BalconyView,
      RoomService,
      bathroomShower,
      bathroomShowerOne,
      bathroomShowerSoap,
      bathroomShowerTwo,
      Lobby,
    ],
    roomPriceWithBreakfast: 32.99,
    roomPriceWithoutBreakfast: 27.99,
    isBookAble: true,
    RoomService: [
      {
        icon: (
          <ShowerIcon
            style={{
              color: "#2D9596",
              fontSize: 35,
            }}
          />
        ),
        label: "24/7 hot water",
      },
      {
        icon: (
          <FreeBreakfastIcon
            style={{
              color: "#2D9596",
              fontSize: 35,
            }}
          />
        ),
        label: "Breakfast Included",
      },
      {
        icon: (
          <NetworkWifiIcon
            style={{
              color: "#2D9596",
              fontSize: 35,
            }}
          />
        ),
        label: "2G/5G Wifi",
      },
      {
        icon: (
          <LocalHotelIcon
            style={{
              color: "#2D9596",
              fontSize: 35,
            }}
          />
        ),
        label: "Pax : upto 2 people",
      },
      {
        icon: (
          <InventoryIcon
            style={{
              color: "#2D9596",
              fontSize: 35,
            }}
          />
        ),
        label: "1 Drawer",
      },
      {
        icon: (
          <LiveTvIcon
            style={{
              color: "#2D9596",
              fontSize: 35,
            }}
          />
        ),
        label: "Available in living room",
      },
      // {
      //   icon: LivingRoomIcon,
      //   label: "Guest rooms connected to a sha#2D9596 living area",
      // },
    ],
  },
  {
    id: 3,
    roomName: "King Bed Room",
    roomSlogan: "Where the Hospitality of Kathmandu Lives",
    mainImage: KingBedFour,
    subImage: RoomService,
    subImageOne: bathroomMain,
    allImages: [
      KingBedFour,
      KingBedOne,
      BalconyView,
      RoomService,
      bathroomMain,
      KingBedTwo,
      KingBedThree,
      KingBedFour,
      bathroomShower,
      bathroomShowerOne,
      bathroomShowerSoap,
      bathroomShowerTwo,
      KingBedEight,
    ],
    roomPriceWithBreakfast: 32.99,
    roomPriceWithoutBreakfast: 27.99,
    isBookAble: true,
    RoomService: [
      {
        icon: (
          <ShowerIcon
            style={{
              color: "#2D9596",
              fontSize: 35,
            }}
          />
        ),
        label: "24/7 hot water",
      },
      {
        icon: (
          <FreeBreakfastIcon
            style={{
              color: "#2D9596",
              fontSize: 35,
            }}
          />
        ),
        label: "Breakfast Included",
      },
      {
        icon: (
          <NetworkWifiIcon
            style={{
              color: "#2D9596",
              fontSize: 35,
            }}
          />
        ),
        label: "2G/5G Wifi",
      },
      {
        icon: (
          <LocalHotelIcon
            style={{
              color: "#2D9596",
              fontSize: 35,
            }}
          />
        ),
        label: "Pax : upto 2 people",
      },
      {
        icon: (
          <InventoryIcon
            style={{
              color: "#2D9596",
              fontSize: 35,
            }}
          />
        ),
        label: "1 Drawer",
      },
      {
        icon: (
          <LiveTvIcon
            style={{
              color: "#2D9596",
              fontSize: 35,
            }}
          />
        ),
        label: "Available in living room",
      },
      // {
      //   icon: LivingRoomIcon,
      //   label: "Guest rooms connected to a sha#2D9596 living area",
      // },
    ],
  },
];
