import { useState } from "react";
import "./footer.module.css";

import { Box, Typography } from "@mui/material";
import {
  BalconyView,
  CityVIew,
  CityViewImg,
  GuestOne,
  GuestTwo,
  KingBedEight,
  KingBedFour,
  KingBedOne,
  KingBedThree,
  KingBedTwo,
  Lobby,
  Logo,
  RoomService,
  ScaredTrailsLocation,
  TwinBedRoomFive,
  TwinBedRoomOne,
  TwinBedRoomTwo,
  bathroomGel,
  bathroomMain,
  bathroomShower,
  bathroomShowerOne,
  bathroomShowerSoap,
  bathroomShowerTwo,
} from "src/assets";
import Modal from "src/component/modalPopUp";

const Footer = () => {
  const allImages = [
    CityVIew,
    CityViewImg,
    GuestOne,
    GuestTwo,
    Lobby,
    TwinBedRoomFive,
    TwinBedRoomOne,
    TwinBedRoomTwo,
    bathroomMain,
    bathroomGel,
    BalconyView,
    RoomService,
    bathroomShower,
    bathroomShowerOne,
    bathroomShowerSoap,
    bathroomShowerTwo,
    Lobby,
    KingBedFour,
    KingBedOne,
    KingBedTwo,
    KingBedThree,
    KingBedEight,
  ];

  const images = [CityVIew, CityViewImg, GuestOne];
  const images_Two = [Lobby, KingBedFour, KingBedOne];
  const [selectedImage, setSelectedImage] = useState(null);
  const [open, setOpen] = useState(false);

  const handleOpen = (image) => {
    setSelectedImage(image);
    setOpen(true);
  };

  return (
    <footer>
      <Box
        sx={{
          flex: "1",
          padding: "20px",
        }}
      >
        <Typography variant="h4" component={"h4"}>
          Sacred Trails Home-Stay
        </Typography>
        <Box
          sx={{
            width: "100%",
            maxWidth: "200px",
            margin: "10px 0px",
          }}
        >
          <img src={Logo} alt="Sacred Trails Home-stay" width={"100%"} />
        </Box>
        <Typography variant="body1" component={"p"}>
          Sacred Trails is the realization of a long-held dream for Hari Chandra
          Giri, the patriarch of the Giri family. Hari has been working in the
          tourist, hospitality, and hotel industry since 1987. He started as a
          trekking guide along the Annapurna and Everest trekking circuits....
        </Typography>

        <ul style={{ listStyleType: "none", padding: 0 }}>
          <li style={{ marginBottom: "10px" }}>
            Email - sacredtrails.np@gmail.com
          </li>
          <li style={{ marginBottom: "10px" }}>
            Contact Number - +977-984 1382 450
          </li>
        </ul>
      </Box>

      <Box>
        <Box
          sx={{
            flex: "1",
            display: "flex",
            gap: "10px",
            flexWrap: "wrap",
          }}
        >
          {images.map((image, index) => (
            <Box
              key={index}
              sx={{ cursor: "pointer", marginBottom: "10px" }}
              onClick={() => handleOpen(image)}
              width={{
                xs: "100%",
                sm: "48%",
                md: "30%",
                lg: "27%",
              }}
              height={{ xs: "300px", sm: "150px", md: "150px", lg: "150px" }}
            >
              <img
                src={image}
                alt="images"
                width="100%"
                height="100%"
                style={{
                  borderRadius: 10,
                  objectFit: "cover",
                  objectPosition: "top",
                }}
              />
            </Box>
          ))}
        </Box>
        <Box
          sx={{
            flex: "1",
            display: "flex",
            gap: "10px",
            flexWrap: "wrap",
          }}
        >
          {images_Two.map((image, index) => (
            <Box
              key={index}
              sx={{ cursor: "pointer", marginBottom: "10px" }}
              onClick={() => handleOpen(image)}
              width={{ xs: "100%", sm: "48%", md: "30%", lg: "27%" }}
              height={{ xs: "300px", sm: "150px", md: "150px", lg: "150px" }}
            >
              <img
                src={image}
                alt="images"
                width="100%"
                height="100%"
                style={{
                  borderRadius: 10,
                  objectFit: "cover",
                  objectPosition: "top",
                }}
              />
            </Box>
          ))}
        </Box>
        <Modal
          images={allImages}
          open={open}
          handleClose={() => setOpen(false)}
          selectedImage={selectedImage}
        />
      </Box>

      <Box
        sx={{
          flex: "1",
        }}
      >
        <Box
          sx={{
            flex: "1",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <ul style={{ listStyleType: "none", padding: 0 }}>
            <li style={{ marginTop: 10 }}>
              <img
                src={ScaredTrailsLocation}
                alt="ScaredTrailsLocation"
                width={"100%"}
                height={310}
                style={{
                  objectFit: "contain",
                }}
              />
            </li>
          </ul>
        </Box>
      </Box>
    </footer>
  );
};

export default Footer;
