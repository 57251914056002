/* eslint-disable jsx-a11y/iframe-has-title */
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  borderRadius: 5,
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  maxWidth: "800px",
  maxHeight: "90vh", // Set maximum height to 90% of the viewport height
  width: "90vw", // Set width to 90% of the viewport width
  px: 2,
  py: 2,
  overflowY: "auto", // Enable vertical scrolling if content overflows
};

const ModalPopUpDetail = (props) => {
  return (
    <div>
      <Modal
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box
            display={"flex"}
            width={"100%"}
            textAlign={"right"}
            justifyContent={"end"}
            mb={"20px"}
            color={"#2D9596"}
            fontSize={10}
            sx={{
              cursor: "pointer",
            }}
            onClick={props.handleClose}
          >
            <HighlightOffIcon
              sx={{
                fontSize: "30px",
              }}
            />
          </Box>
          <Box
            sx={{
              maxHeight: 400,
              maxWidth: 1000,
              overflow: "hidden",
              borderRadius: 5,
            }}
          >
            <img
              src={props.data.imageItem}
              width={"100%"}
              height={"100%"}
              alt="background images"
              style={{
                objectFit: "cover",
              }}
            />
          </Box>
          <Box
            sx={{
              py: 2,
            }}
          >
            <Typography
              variant="h2"
              component={"h2"}
              sx={{
                color: "#000",
                fontSize: ["20px", "35px"],
                fontWeight: [400, 400],
                textAlign: "center",
              }}
            >
              {props.data.eventSubTitle}
            </Typography>
            <Typography
              variant="h6"
              component={"h6"}
              color={"#2D9596"}
              textAlign={"center"}
            >
              {props.data.eventTitle}
            </Typography>
          </Box>
          {props.data?.eventDescriptionDetail?.map((desc) => (
            <Typography>{desc}</Typography>
          ))}
          <Box py={4}>
            <Typography fontSize={18} fontWeight="500">
              Meet the Author
            </Typography>

            <Box display="flex" alignItems="center">
              <Box>
                <Typography fontSize={16} mb={1}>
                  {props.data?.eventAuthor}
                </Typography>
                <Typography color="text.secondary">
                  {props.data?.authorDescription}
                </Typography>
              </Box>
            </Box>
          </Box>

          <Box width={"100%"} m={"25px auto"} textAlign={"center"}>
            <Button
              variant="contained"
              sx={{
                boxShadow: "0px 8px 15px 0px rgba(50, 82, 223, 0.30)",
                maxWidth: ["100%", "250px"],
                minHeight: "50px",
                marginTop: 2,
                alignSelf: ["center", "flex-end"],
                borderRadius: "64px",
                background: "var(--Rectangle-805, #34A0A4)",
                padding: "5px 30px",
                "&:hover": {
                  background: "#34A0A4",
                },
                margin: "0px auto",
                width: "100%",
              }}
              onClick={() => props.setShowDirection(!props.showDirection)}
            >
              {props.showDirection ? "Hide Direction" : "Get Direction"}
            </Button>
          </Box>
          <Box>{props.showDirection && props.data?.location}</Box>
        </Box>
      </Modal>
    </div>
  );
};

export default ModalPopUpDetail;
