export const items = [
  {
    key: "1",

    to: "/",
    name: "Home",
  },
  {
    key: "2",

    to: "/about",
    name: "About",
  },
  {
    key: "3",

    to: "/#rooms",
    name: "Rooms",
  },
  {
    key: "4",

    to: "/#events",
    name: "Event",
  },
  // {
  //   key: "5",

  //   to: "/contact",
  //   name: "Contact",
  // },
];
