import BedOutlinedIcon from "@mui/icons-material/BedOutlined";
import GroupsOutlinedIcon from "@mui/icons-material/GroupsOutlined";
import { Box, Button, Container, Typography } from "@mui/material";
import Carousel from "react-material-ui-carousel";
import {
  BackgroundIcon,
  BookingDotComIcon,
  BuildingFront,
  BuildingSide,
  CityViewImg,
  GuestOne,
  GuestTwo,
  KingBedOne,
  RoomService,
  TripAdvisor,
  TwinBedRoomTwo,
  bathroomMain,
  libraryTwo,
} from "src/assets";
import Event from "src/component/event";
import RoomBanner from "src/component/roomBanner";
import "swiper/swiper-bundle.min.css";
import "./home.module.css";

import { useNavigate } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import WhyUs from "src/component/whySacred";

const HomePage = () => {
  const navigate = useNavigate();

  const images = [
    RoomService,
    TwinBedRoomTwo,
    KingBedOne,
    GuestOne,
    BuildingFront,
    libraryTwo,
    CityViewImg,
    bathroomMain,
    GuestTwo,
    BuildingSide,
  ];

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  const handleAboutUs = () => {
    navigate(`/about`);
    scrollToTop();
  };

  return (
    <>
      <Box
        sx={{
          position: "relative",
          width: "100%",
          height: "100%",
          paddingBottom: "135px",
        }}
      >
        <Container sx={{ marginTop: [12, 12, 16] }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: ["column", "column", "row"],
              justifyContent: ["center", "center", "space-between"],
              position: "relative",
            }}
          >
            <Box
              sx={{
                zIndex: 10,
                marginTop: [4, 4, 18],
                textAlign: ["center", "center", "left"],
              }}
            >
              <Box>
                <Typography
                  variant="h6"
                  component="h6"
                  sx={{
                    color: "#152c5b",
                    fontSize: [28, 32, 42],
                    fontWeight: 700,
                  }}
                >
                  Forget Busy Work, <br /> Start Next Vacation
                </Typography>
                <Typography
                  variant="body1"
                  component="p"
                  sx={{
                    color: "#152c5b",
                    fontSize: [14, 16, 17],
                    fontWeight: 300,
                    marginTop: 3,
                  }}
                >
                  We provide what you need to enjoy your holiday with <br />
                  family. Time to make another memorable moment.
                </Typography>
              </Box>
              <HashLink to={"/#rooms"}>
                <Button
                  variant="contained"
                  sx={{
                    borderRadius: 1,
                    background: "#2D9596",
                    boxShadow: "0px 8px 15px 0px rgba(50, 82, 223, 0.30)",
                    marginTop: 6,
                    minWidth: ["100%", "100%", "210px"],
                    minHeight: "50px",
                    "&:hover": {
                      background: "#34A0A4", // Change this to the color you want on hover
                    },
                  }}
                >
                  Show Me Rooms
                </Button>
              </HashLink>
              <Box sx={{ display: "flex", marginTop: 6 }}>
                <Box sx={{ marginRight: [4, 0, 5] }}>
                  <GroupsOutlinedIcon
                    sx={{ color: "#2D9596", width: 30, height: 30 }}
                  />
                  <Typography variant="body1">12 Guests</Typography>
                </Box>
                <Box>
                  <BedOutlinedIcon
                    sx={{ color: "#2D9596", width: 30, height: 30 }}
                  />
                  <Typography variant="body1">6 Rooms </Typography>
                </Box>
              </Box>
            </Box>
            <Box
              sx={{
                zIndex: 10,
                position: "relative",
                marginTop: [4, 4, 8],
              }}
            >
              <Box
                sx={{
                  height: ["auto", "auto", "183px"],
                  width: ["100%", "100%", "133px"],
                  position: "absolute",
                  zIndex: -10,
                  right: ["0", "0", "-46px"],
                  top: ["0", "0", "-45px"],
                  backgroundColor: "#2D9596",
                  borderRadius: 1,
                }}
                className="dot-pattern"
              />
              <Box
                sx={{
                  position: "relative",
                  minWidth: ["100%", "550px", "550px"],
                  maxWidth: ["100%", "550px", "550px"],
                  width: "100%",
                  height: "100%",
                }}
              >
                <Carousel animation="fade" duration={3000} indicators={false}>
                  {images.map((pic, index) => (
                    <Box
                      key={index}
                      sx={{
                        position: "relative",
                        maxWidth: "550px",
                        maxHeight: ["auto", "550px", "550px"],
                        objectFit: "cover",
                        overflow: "hidden",
                        borderRadius: "24px",
                      }}
                    >
                      <img
                        src={pic}
                        alt={`pic ${index}`}
                        width="100%"
                        height="100%"
                      />
                    </Box>
                  ))}
                </Carousel>
              </Box>
              <Box
                sx={{
                  height: ["auto", "auto", "183px"],
                  width: ["100%", "100%", "133px"],
                  position: "absolute",
                  zIndex: -10,
                  left: ["0", "0", "-46px"],
                  bottom: ["0", "0", "30px"],
                  backgroundColor: "#2D9596",
                  borderRadius: 1,
                }}
              />
            </Box>
          </Box>
        </Container>
        <Box
          sx={{
            position: "absolute",
            bottom: "10px",
            width: "100%",
          }}
        >
          <img
            src={BackgroundIcon}
            alt="BackgroundIcon"
            width="100%"
            height="100%"
          />
        </Box>
      </Box>
      {/* About US */}
      <Container>
        <Box
          sx={{
            display: "flex",
            flexDirection: ["column", "column", "row"],
            justifyContent: ["center", "center", "space-between"],
            marginTop: [8, 8, 13],
          }}
        >
          <Box
            sx={{
              zIndex: 10,
              marginTop: [0, 4, 9],
              position: "relative",
              maxHeight: "390px",
            }}
          >
            <Box
              sx={{
                position: "sticky",
                top: [0, 1000],
              }}
            >
              <Box
                sx={{
                  height: ["auto", "auto", "183px"],
                  width: ["100%", "100%", "133px"],
                  position: "absolute",
                  zIndex: -10,
                  right: ["0", "0", "-46px"],
                  top: ["0", "0", "-45px"],
                  backgroundColor: "#2D9596",
                  borderRadius: 1,
                }}
              />
              <Box
                sx={{
                  maxWidth: ["100%", "440px", "440px"],

                  objectFit: "contain",
                }}
              >
                <img
                  src={BuildingFront}
                  width="100%"
                  height="100%"
                  alt="background images"
                  style={{ borderRadius: "24px" }}
                />
              </Box>
              <Box
                sx={{
                  height: ["auto", "auto", "183px"],
                  width: ["100%", "100%", "133px"],
                  position: "absolute",
                  zIndex: -10,
                  left: ["0", "0", "-46px"],
                  bottom: ["0", "0", "-40px"],
                  backgroundColor: "#2D9596",
                  borderRadius: 1,
                }}
              />
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 2,
              position: "relative",
              zIndex: 10,
              maxWidth: ["100%", "100%", "50%"],
            }}
          >
            <Typography
              variant="h2"
              component="h2"
              sx={{
                marginTop: [2, 8, 10],
                color: "#152c5b",
                fontSize: [28, 32, 42],
                fontWeight: 700,
                textAlign: ["center", "center", "center"],
              }}
            >
              About Us
            </Typography>
            <Typography
              variant="body1"
              component="p"
              sx={{
                fontSize: [16, 18, 20],
                paddingLeft: [0, 0, 0],
                color: "#152c5b",
                fontWeight: 300,
                marginTop: [2, 3, 3],
              }}
            >
              Sacred Trails is the realization of a long-held dream for Hari
              Giri, the patriarch of the Giri family. Hari has been working in
              the tourist, hospitality, and hotel industry since 1987. He
              started as a trekking guide along the Annapurna and Everest
              trekking circuits, then became an assistant manager, and finally,
              the manager of a hotel in Thamel. All the while, he and his wife
              were raising a young family of five children. In 2023, Hari's
              dream of owning his own boutique homestay came true. He sold a
              family property, a hotel in Thamel, and a jeep, and invested in
              Sacred Trails Homestay. Hari is now the proud owner and operator
              of the business, overseeing their collective destiny. Together
              with his four beautiful daughters, Indu, Bindu, Karuna, and
              Saruna, his son, Shiva, and his wife, Laxmi, they bring the true
              hospitality of Kathmandu to life....
            </Typography>

            <Button
              variant="contained"
              sx={{
                borderRadius: 1,
                background: "#2D9596",
                boxShadow: "0px 8px 15px 0px rgba(50, 82, 223, 0.30)",
                maxWidth: ["100%", "100%", "210px"],
                minHeight: "50px",
                marginTop: 2,
                alignSelf: ["center", "center", "flex-end"],
                "&:hover": {
                  background: "#34A0A4", // Change this to the color you want on hover
                },
              }}
              onClick={handleAboutUs}
            >
              Learn More
            </Button>
          </Box>
        </Box>
      </Container>
      {/* Rooms */}
      <Box sx={{ position: "relative" }} id="rooms">
        <Container>
          <RoomBanner />
        </Container>
        <Box sx={{ position: "absolute", bottom: "0", width: "100%" }}>
          <img src={BackgroundIcon} alt="BackgroundIcon" width={"100%"} />
        </Box>
      </Box>

      {/* Why as sacred trails */}
      <Container>
        <WhyUs />
      </Container>

      {/* Events */}
      <Box id="events">
        <Event />
      </Box>

      <Container sx={{ marginTop: [7, 14] }}>
        <Typography
          variant="h2"
          component={"h2"}
          sx={{
            color: "#000",
            fontSize: ["35px", "56px"],
            fontWeight: [500, 700],
            textAlign: "center",
          }}
        >
          OUR PARTNERS
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            gap: ["50px", "100px"],
            marginTop: [4, 8],
            flexDirection: ["column", "row"],
          }}
        >
          <Box
            sx={{
              maxWidth: ["100%", "221px"],
              fontSize: "55px",
            }}
          >
            <img
              src={BookingDotComIcon}
              width={"100%"}
              alt="background images"
            />
          </Box>
          <Box
            sx={{
              maxWidth: ["100%", "221px"],
              marginBottom: ["20px", "56px"],
              fontSize: "55px",
            }}
          >
            <img src={TripAdvisor} width={"100%"} alt="background images" />
          </Box>
        </Box>
      </Container>
    </>
  );
};
export default HomePage;
