import { Box, Container, Typography } from "@mui/material";
import { BackgroundIcon, OutSidePhoto } from "src/assets";
import RoomBanner from "src/component/roomBanner";

const AboutUs = () => {
  return (
    <>
      <Container sx={{ marginTop: 16 }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: ["column", "row"],
            justifyContent: "space-between",
            marginTop: [8, 0],
          }}
        >
          <Box
            sx={{
              zIndex: 10,
              position: "relative",
              marginTop: [3, 0],
            }}
          >
            <Box
              sx={{
                position: "sticky",
                minWidth: ["100%", 450],
                top: [0, 200],
              }}
            >
              <Box
                sx={{
                  height: ["auto", "183px"],
                  width: ["100%", "133px"],
                  position: "absolute",
                  zIndex: -10,
                  right: ["auto", "-20px"],
                  top: ["auto", "-45px"],
                  bottom: ["-45px", "auto"],
                  backgroundColor: "#2D9596",
                  borderRadius: 1,
                }}
              />
              <Box
                sx={{
                  position: "relative",
                  maxWidth: 450,
                  maxHeight: [300, 800],
                  objectFit: "cover",
                  overflow: "hidden",
                  borderRadius: ["24px", "10px"],
                }}
              >
                <img src={OutSidePhoto} alt={"about us"} width={"100%"} />
              </Box>
              <Box
                sx={{
                  height: ["auto", "183px"],
                  width: ["100%", "133px"],
                  position: "absolute",
                  zIndex: -10,
                  left: ["-46px", "-30px"],
                  right: ["auto", "auto"],
                  bottom: ["-45px", "-40px"],
                  top: ["auto", "auto"],
                  backgroundColor: "#2D9596",
                  borderRadius: 1,
                }}
              />
            </Box>
          </Box>
          <Box
            sx={{
              padding: [0, 5],
              marginTop: [0, 4],
              textAlign: ["left", "left"],
              flex: 1,
            }}
          >
            <Typography
              variant="h2"
              component={"h2"}
              sx={{
                marginTop: [3, 0],
                marginBottom: [1, 3],
                color: "#152c5b",
                fontSize: ["35px", "56px"],
                fontWeight: [500, 700],
                textAlign: "center",
              }}
            >
              About Us
            </Typography>

            <Typography
              variant="body1"
              component={"p"}
              sx={{
                fontSize: [16, 20],
                paddingLeft: [0, 3],
                color: "#152c5b",
                fontWeight: 300,
                textAlign: ["left", "left"],
                marginBottom: 2,
                marginTop: [3, 0],
              }}
            >
              Sacred Trails is the realization of a long-held dream for Hari
              Giri, the patriarch of the Giri family. Hari has been working in
              the tourist, hospitality, and hotel industry since 1987. He
              started as a trekking guide along the Annapurna and Everest
              trekking circuits, then became an assistant manager, and finally,
              the manager of a hotel in Thamel. All the while, he and his wife
              were raising a young family of five children. In 2023, Hari's
              dream of owning his own boutique homestay came true. He sold a
              family property, a hotel in Thamel, and a jeep, and invested in
              Sacred Trails Homestay. Hari is now the proud owner and operator
              of the business, overseeing their collective destiny. Together
              with his four beautiful daughters, Indu, Bindu, Karuna, and
              Saruna, his son, Shiva, and his wife, Laxmi, they bring the true
              hospitality of Kathmandu to life.
            </Typography>

            <Typography
              variant="body1"
              component={"p"}
              sx={{
                fontSize: [16, 20],
                paddingLeft: [0, 3],
                color: "#152c5b",
                fontWeight: 300,
                textAlign: ["left", "left"],
                marginBottom: 2,
                marginTop: [3, 0],
              }}
            >
              This endeavor has been a labor of love and a lot of hard work for
              the Giri family. During COVID, it was challenging to pay rent for
              the property, especially with no customers for more than 7 to 8
              months. Running out of money and facing uncertainties in the
              Thamel hospitality business, Hari decided to sell the property.
              However, the money wasn't enough for a fresh start due to the
              significant rent payments. After discussions with many people, the
              family decided to take a break for a couple of months. Eventually,
              they sold their property in Balaju and a jeep, providing them with
              the funds to start a new business and build a new home for their
              customers in Budhanikantha. Ideally located in a quiet street in
              the heart of Budhanilkantha, the homestay is now beautifully
              adorned with traditional Nepalese art, antique woodwork in the
              lobby and garden area, fresh paint, windows, doors, plumbing,
              electrical and lighting systems, etc. They have the bathroom
              facilities with modern fixtures and provide clean towels and
              linens, as well as high-speed wifi. Additionally, there is a
              full-service rooftop restaurant offering unique views of the city
              and forests, serving everything from homemade banana pancakes for
              breakfast to traditional Nepalese cuisine for dinner.
            </Typography>
            <Typography
              variant="body1"
              component={"p"}
              sx={{
                fontSize: [16, 20],
                paddingLeft: [0, 3],
                color: "#152c5b",
                fontWeight: 300,
                textAlign: ["left", "left"],
                marginBottom: 2,
                marginTop: [3, 0],
              }}
            >
              More important than the physical improvements is the genuine
              caring and hospitality of the Giri family and the entire staff. As
              William Shakespeare once said, "What is the city, but the people?"
              This is never truer than in Kathmandu. The Giri family will
              provide you with some of your most cherished memories of Nepal.
              Their friendly, helpful, talkative (though sometimes shy) demeanor
              is sincere and endearing. Ask them for anything, and they will do
              everything in their power to make it happen. They are here to make
              your stay in Kathmandu a beautiful and memorable experience. You
              will arrive as a guest and leave as family.
              <br />
            </Typography>
            <Typography
              variant="body1"
              component={"p"}
              sx={{
                fontSize: [16, 20],
                paddingLeft: [0, 3],
                color: "#152c5b",
                fontWeight: 300,
                textAlign: ["left", "left"],
                marginBottom: 2,
                marginTop: [3, 0],
              }}
            >
              Sacred Trails Homestay is a warm, clean, and inviting place to
              stay, offering sanctuary and peace from the busy streets of
              Kathmandu. The entire Giri family welcomes you. Their home is
              yours.
            </Typography>

            <Typography
              variant="body1"
              component={"p"}
              sx={{
                fontSize: [16, 20],
                paddingLeft: [0, 3],
                color: "#152c5b",
                fontWeight: 300,
                textAlign: ["center", "center"],
                marginBottom: 2,
                marginTop: [3, 0],
              }}
            >
              "Where the Hospitality of Kathmandu Lives"
              <br />
            </Typography>
          </Box>
        </Box>
      </Container>
      {/* Rooms */}
      <Box sx={{ position: "relative", overflow: "hidden" }}>
        <Container>
          <RoomBanner />
        </Container>
        <Box sx={{ position: "absolute", bottom: "0", width: "100%" }}>
          <img src={BackgroundIcon} alt="BackgroundIcon" width={"100%"} />
        </Box>
      </Box>
    </>
  );
};

export default AboutUs;
