import { Box, Modal } from "@mui/material";
import Gallery from "../gallery";

const ModalPopUp = ({ images, open, handleClose, selectedImage }) => {
  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            width: ["100%", "1024px"],
            p: 4,
          }}
        >
          <Gallery images={images} selectedImage={selectedImage} />
        </Box>
      </Modal>
    </>
  );
};

export default ModalPopUp;
