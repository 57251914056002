import { toast, ToastOptions, ToastPosition } from "react-toastify";

export const notify = (message: string, type: "success" | "error" | "info") => {
  let finalMessage = message;
  if (typeof finalMessage === "object") {
    finalMessage = Object.values(message).join("\n");
  }
  const commonOptions: ToastOptions<Record<string, never>> = {
    position: "top-left" as ToastPosition,
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  };

  switch (type) {
    case "success":
      toast.success(finalMessage, commonOptions);
      break;
    case "error":
      toast.error(finalMessage, commonOptions);
      break;
    default:
      toast.info(finalMessage, commonOptions);
  }
};
