import { Box } from "@mui/material";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation } from "swiper";

import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import "swiper/components/navigation/navigation.min.css";

SwiperCore.use([Navigation]);

const Gallery = ({ images, selectedImage }) => {
  const initialSlideIndex = images.findIndex(
    (image) => image === selectedImage
  );

  return (
    <>
      <Swiper
        spaceBetween={10}
        slidesPerView={1}
        navigation={true}
        grabCursor={true}
        initialSlide={initialSlideIndex}
        breakpoints={{
          0: {
            slidesPerView: 1,
          },
          400: {
            slidesPerView: 1,
          },
          639: {
            slidesPerView: 1,
          },
          865: {
            slidesPerView: 1,
          },
          1000: {
            slidesPerView: 1,
          },
          1500: {
            slidesPerView: 1,
          },
          1700: {
            slidesPerView: 1,
          },
        }}
      >
        {images.map((item, index) => (
          <SwiperSlide key={index}>
            <Box
              sx={{
                maxWidth: "1024px",
                maxHeight: "800px",
                objectFit: ["cover", "contain"],
                objectPosition: "top",
              }}
            >
              <img
                src={item}
                alt={`sacred trails ${index + 1}`}
                width={"100%"}
                height={"100%"}
              />
            </Box>
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  );
};

export default Gallery;
