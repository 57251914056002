import { BrowserRouter } from "react-router-dom";
import "./App.css";
import Footer from "./component/footer";
import Navbar from "./component/navbar";
import MainRoutes from "./routes";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function App() {
  return (
    <>
      <BrowserRouter>
        <Navbar />
        <MainRoutes />
        <Footer />
      </BrowserRouter>
      <ToastContainer />
    </>
  );
}

export default App;
