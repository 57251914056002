import { Box, Button, Checkbox, Container, Typography } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";

import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import React, { useState } from "react";
import {
  BackgroundIcon,
  BalconyView,
  KingBedEight,
  KingBedFour,
  KingBedOne,
  KingBedThree,
  KingBedTwo,
  Lobby,
  RoomService,
  TwinBedRoomFive,
  TwinBedRoomOne,
  TwinBedRoomTwo,
  bathroomGel,
  bathroomMain,
  bathroomShower,
  bathroomShowerOne,
  bathroomShowerSoap,
  bathroomShowerTwo,
} from "src/assets";
import Modal from "src/component/modalPopUp";
import RoomBanner from "src/component/roomBanner";
import "./room.style.css";

import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { useNavigate, useParams } from "react-router-dom";
import { RoomDetailsData } from "src/utils/review-images";

const RoomDetail = () => {
  const allImages = [
    TwinBedRoomFive,
    TwinBedRoomOne,
    TwinBedRoomTwo,
    TwinBedRoomFive,
    bathroomMain,
    bathroomGel,
    BalconyView,
    RoomService,
    bathroomShower,
    bathroomShowerOne,
    bathroomShowerSoap,
    bathroomShowerTwo,
    Lobby,
  ];
  const doubleRoomImage = [
    KingBedFour,
    KingBedOne,
    BalconyView,
    RoomService,
    bathroomMain,
    KingBedTwo,
    KingBedThree,
    KingBedFour,
    bathroomShower,
    bathroomShowerOne,
    bathroomShowerSoap,
    bathroomShowerTwo,
    KingBedEight,
  ];
  const { id } = useParams();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [checked, setChecked] = React.useState(true);
  const [errorMessage, setErrorMessage] = React.useState("");

  const RoomItem = RoomDetailsData.filter((item) => item.id === Number(id));

  const [value, setValue] = useState(1);
  const [dateRange, setDateRange] = useState([
    {
      startDate: new Date(),
      endDate: undefined,
      key: "selection",
    },
  ]);

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  const handleOpen = (image) => {
    setSelectedImage(image);
    setOpen(true);
  };

  const handleCheckInSelect = (checkInDate) => {
    setDateRange([
      {
        startDate: checkInDate.toDate(),
        endDate: dateRange[0].endDate,
        key: "selection",
      },
    ]);
  };

  const handleCheckOutSelect = (checkOutDate) => {
    setDateRange((prevDateRange) => {
      return [
        {
          startDate: prevDateRange[0].startDate,
          endDate: checkOutDate.toDate(),
          key: "selection",
        },
      ];
    });

    const totalNights = Math.ceil(
      (checkOutDate.toDate().getTime() - dateRange[0].startDate.getTime()) /
        (1000 * 60 * 60 * 24)
    );
    setValue(totalNights);

    if (checkOutDate) {
      setErrorMessage("");
    }
  };

  const disableCheckoutDate = (date) => {
    if (dateRange[0].startDate) {
      return dayjs(date) <= dayjs(dateRange[0].startDate);
    }
    return false;
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };

  const handleBooking = () => {
    const bookingData = {
      roomName: RoomItem[0].roomName,
      isBreakfast: checked,
      mainPhoto: RoomItem[0].mainImage,
      bookingDate: "2023-01-01",
      roomPrice: checked
        ? RoomItem[0].roomPriceWithBreakfast
        : RoomItem[0].roomPriceWithoutBreakfast,
      checkIn: dateRange[0].startDate,
      checkOut: dateRange[0].endDate,
    };
    const encodedData = encodeURIComponent(JSON.stringify(bookingData));

    if (dateRange[0].endDate) {
      scrollToTop();
    }

    if (dateRange[0].endDate) {
      navigate(`/room/booking?data=${encodedData}`);
    } else {
      setErrorMessage("Please select Check-out Date");
    }
  };

  return (
    <>
      <Container
        sx={{
          marginTop: 19,
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: ["column", "row"],
            alignItems: "center",
            width: "100%",
          }}
        >
          <Box>
            <Typography
              variant="h2"
              component={"h2"}
              sx={{
                color: "#152c5b",
                textAlign: "center",
                fontSize: ["35px", "56px"],
                fontWeight: [500, 700],
              }}
            >
              {RoomItem[0].roomName}
            </Typography>
            <Typography
              variant="h6"
              component={"h6"}
              sx={{
                color: "#2D9596",
                textAlign: "center",
                paddingTop: 1,
              }}
            >
              {RoomItem[0].roomSlogan}
            </Typography>
          </Box>
        </Box>
        <Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: ["column", "row"],
              gap: "10px",
              flexWrap: "wrap",
              marginTop: 4,
            }}
          >
            <Box
              sx={{
                cursor: "pointer",
                borderRadius: "15px",
                overflow: "hidden",
                width: ["100%", 643],
                height: ["100%", 600],
              }}
              onClick={() => handleOpen(RoomItem[0].mainImage)}
            >
              <img
                src={RoomItem[0].mainImage}
                alt="images"
                width={"100%"}
                height={"100%"}
                style={{
                  borderRadius: 10,
                  objectFit: "cover",
                  objectPosition: "top",
                  overflow: "hidden",
                }}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                borderRadius: "15px",
                overflow: "hidden",
              }}
            >
              <Box
                sx={{
                  cursor: "pointer",
                  width: ["100%", 487],
                  height: [300],
                }}
                onClick={() => handleOpen(RoomItem[0].subImage)}
              >
                <img
                  src={RoomItem[0].subImage}
                  alt="images"
                  width={"100%"}
                  height={"100%"}
                  style={{
                    borderRadius: 10,
                    objectFit: "cover",
                    objectPosition: "top",
                    overflow: "hidden",
                  }}
                />
              </Box>
              <Box
                sx={{
                  cursor: "pointer",
                  width: ["100%", 487],
                  height: [295],
                  marginTop: 1,
                }}
                onClick={() => handleOpen(RoomItem[0].subImageOne)}
              >
                <img
                  src={RoomItem[0].subImageOne}
                  alt="images"
                  width={"100%"}
                  height={"100%"}
                  style={{
                    borderRadius: 10,
                    objectFit: "cover",
                    objectPosition: "top",
                  }}
                />
              </Box>
            </Box>
            <Modal
              images={
                RoomItem[0].roomName === "Twin Bed Room"
                  ? allImages
                  : doubleRoomImage
              }
              open={open}
              handleClose={() => setOpen(false)}
              selectedImage={selectedImage}
            />
          </Box>
        </Box>
        {/* About Rooms */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: ["column", "row"],
            gap: 3,
            marginTop: 3,
            zIndex: 10,
            position: "relative",
          }}
        >
          <Box>
            <Typography
              variant="h2"
              component={"h2"}
              sx={{
                color: "#152C5B",
                fontSize: "20px",
                fontWeight: 500,
              }}
            >
              About the place
            </Typography>
            <Box>
              <Box
                sx={{
                  maxWidth: 650,
                  width: "100%",
                  marginTop: 2,
                }}
              >
                <Typography
                  variant="body1"
                  component={"p"}
                  sx={{
                    color: "#B0B0B0",
                    fontSize: "16px",
                    fontWeight: 300,
                  }}
                >
                  Sacred Trails Home Stay has a balcony and is situated in
                  Budhanilkantha, within just 1.5 km of Sleeping Vishnu, 1.5 km
                  of Krishna Temple, 3 km of Jamchen vijaya stupa and 3 km of
                  Shivapuri National Park entry point.
                </Typography>
                <Typography
                  variant="body1"
                  component={"p"}
                  sx={{
                    color: "#B0B0B0",
                    fontSize: "16px",
                    fontWeight: 300,
                  }}
                >
                  Both free WiFi and parking on-site are accessible at the
                  homestay free of charge. Hanuman Dhoka is 10 km from the
                  homestay and Kathmandu Durbar Square is 10 km away. Vegetarian
                  and vegan breakfast options are available each morning at
                  Perfect Home Stay. Guests can also relax in the rooftop.
                </Typography>

                <Typography
                  variant="body1"
                  component={"p"}
                  sx={{
                    color: "#B0B0B0",
                    fontSize: "16px",
                    fontWeight: 300,
                  }}
                >
                  Pashupatinath is 10 km from the accommodation, while
                  Boudhanath Stupa is 9 km from the property. The nearest
                  airport is Tribhuvan International Airport, 12 km from Perfect
                  Home Stay.
                </Typography>
                <Typography
                  variant="body1"
                  component={"p"}
                  sx={{
                    color: "#B0B0B0",
                    fontSize: "16px",
                    fontWeight: 300,
                    textAlign: "center",
                  }}
                >
                  "Where the Hospitality of Kathmandu Lives"
                </Typography>

                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: "repeat(3, 1fr)",
                    gap: "10px",
                    marginTop: "45px",
                    textAlign: "center",
                  }}
                >
                  {RoomItem[0].RoomService?.map((item) => (
                    <Box>
                      {item.icon}
                      <Typography
                        variant="h6"
                        gutterBottom
                        sx={{
                          color: "#152C5B",
                          fontSize: "16px",
                          fontWeight: 400,
                          marginTop: "14px",
                          marginBottom: "14px",
                        }}
                      >
                        {item.label}
                      </Typography>
                    </Box>
                  ))}
                </Box>
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              flex: 1,
              padding: ["20px", "40px 40px"],
              border: "1px solid #E5E5E5",
              borderRadius: "15px",
            }}
          >
            <Box>
              <Typography
                variant="h2"
                component={"h2"}
                sx={{
                  color: "#152C5B",
                  fontSize: "20px",
                  fontWeight: 500,
                }}
              >
                Start Booking
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  gap: 1,
                  marginTop: "14px",
                }}
              >
                <Typography
                  variant="h2"
                  component={"h2"}
                  sx={{
                    color: "#1ABC9C",
                    fontSize: "36px",
                    fontWeight: 275,
                  }}
                >
                  $
                  {checked
                    ? RoomItem[0].roomPriceWithBreakfast
                    : RoomItem[0].roomPriceWithoutBreakfast}
                </Typography>
                <Typography
                  variant="h2"
                  component={"h2"}
                  sx={{
                    color: "#B0B0B0",
                    fontSize: "36px",
                    fontWeight: 500,
                  }}
                >
                  per night
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Box>
                  <Typography
                    variant="body1"
                    component={"p"}
                    sx={{
                      color: "#152C5B",
                      fontSize: "16px",
                      fontWeight: 400,
                    }}
                  >
                    {checked ? "Included Breakfast" : "Breakfast not included"}
                  </Typography>
                </Box>
                <Checkbox
                  checked={checked}
                  onChange={handleChange}
                  inputProps={{ "aria-label": "controlled" }}
                  icon={
                    <FavoriteBorderIcon
                      style={{
                        color: "#2D9596",
                      }}
                    />
                  }
                  checkedIcon={
                    <FavoriteIcon
                      style={{
                        color: "#2D9596",
                      }}
                    />
                  }
                />
              </Box>
            </Box>
            {/* Check In */}
            <Box
              sx={{
                marginTop: "20px",
              }}
            >
              <Typography
                variant="h6"
                gutterBottom
                sx={{
                  color: "#152C5B",
                  fontSize: "16px",
                  fontWeight: 400,
                  marginTop: "14px",
                  marginBottom: "14px",
                }}
              >
                Pick a Date?
              </Typography>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                  }}
                >
                  <DatePicker
                    disablePast
                    label="Check-In"
                    onChange={handleCheckInSelect}
                    sx={{
                      marginRight: [0, "10px"],
                    }}
                  />{" "}
                  <DatePicker
                    label="Check-Out"
                    disablePast
                    onChange={handleCheckOutSelect}
                    shouldDisableDate={disableCheckoutDate}
                    sx={{
                      marginRight: [0, "10px"],
                    }}
                  />
                </Box>
              </LocalizationProvider>
            </Box>

            <Box
              sx={{
                marginTop: "20px",
              }}
            >
              <Typography
                variant="h2"
                component={"h2"}
                sx={{
                  color: "#B0B0B0",
                  fontSize: "16px",
                  fontWeight: 500,
                }}
              >
                You will pay{" "}
                <span
                  style={{
                    color: "#152C5B",
                    fontSize: "16px",
                    fontWeight: 500,
                  }}
                >
                  $
                  {(checked
                    ? RoomItem[0].roomPriceWithBreakfast
                    : RoomItem[0].roomPriceWithoutBreakfast) * value}
                </span>{" "}
                USD for{" "}
                <span
                  style={{
                    color: "#152C5B",
                    fontSize: "16px",
                    fontWeight: 500,
                  }}
                >
                  {value} nights
                </span>
              </Typography>
            </Box>
            {/* book */}
            <Box>
              {RoomItem[0].isBookAble ? (
                <>
                  <Button
                    variant="contained"
                    sx={{
                      borderRadius: 1,
                      background: "#2D9596",
                      boxShadow: "0px 8px 15px 0px rgba(50, 82, 223, 0.30)",
                      marginTop: "20px",
                      minWidth: "210px",
                      minHeight: "50px",
                      "&:hover": {
                        background: "#34A0A4", // Change this to the color you want on hover
                      },
                    }}
                    onClick={handleBooking}
                  >
                    Continue to Book
                  </Button>
                  {errorMessage && (
                    <Typography
                      variant="body1"
                      component={"p"}
                      color="red"
                      mt={2}
                    >
                      {errorMessage}
                    </Typography>
                  )}
                </>
              ) : (
                <Box
                  sx={{
                    padding: "5px",
                    border: "1px solid #152C5B",
                    marginTop: "25px",
                    textAlign: "center",
                  }}
                >
                  <Typography
                    variant="h6"
                    gutterBottom
                    sx={{
                      color: "#152C5B",
                      fontSize: "16px",
                      fontWeight: 400,
                      marginTop: "14px",
                      marginBottom: "14px",
                    }}
                  >
                    This room is not available right now
                  </Typography>
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      </Container>
      <Box sx={{ position: "relative" }}>
        <Container>
          <RoomBanner title={"Other Similar Rooms"} />
        </Container>
        <Box
          sx={{
            position: "absolute",
            bottom: "0",
            width: "100%",
          }}
        >
          <img src={BackgroundIcon} alt="BackgroundIcon" width={"100%"} />
        </Box>
      </Box>
    </>
  );
};

export default RoomDetail;
