import * as React from "react";

import MenuIcon from "@mui/icons-material/Menu";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Toolbar from "@mui/material/Toolbar";

import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

import { Logo } from "src/assets";
import "./navbar.module.css";
import { items } from "./links";

const drawerWidth = 240;

const Navbar = () => {
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
      <Box>
        <img src={Logo} alt="Sacred Trails Home-stay" width={"100%"} />
      </Box>
      <Divider />
      <List>
        {items.map((item) => {
          let hash_link = item.to;
          const words = hash_link.split("");
          return (
            <ListItem key={item.key} disablePadding>
              <ListItemButton sx={{ textAlign: "center" }}>
                {words[1] === "#" ? (
                  <HashLink
                    to={item.to}
                    style={{ textDecoration: "none", color: "inherit" }}
                    onClick={scrollToTop}
                  >
                    <ListItemText primary={item.name} />
                  </HashLink>
                ) : (
                  <Link
                    to={item.to}
                    style={{ textDecoration: "none", color: "inherit" }}
                    onClick={scrollToTop}
                  >
                    <ListItemText primary={item.name} />
                  </Link>
                )}
              </ListItemButton>
            </ListItem>
          );
        })}
      </List>
    </Box>
  );

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        component="nav"
        sx={{ background: "white", color: "black", padding: "0px 20px" }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" } }}
          >
            <MenuIcon />
          </IconButton>

          <Box
            sx={{
              flexGrow: 1,
            }}
          >
            <Link
              to={`/`}
              style={{ textDecoration: "none", color: "inherit" }}
              onClick={scrollToTop}
            >
              <div
                style={{
                  width: 200,
                  height: 110,
                  overflow: "hidden",
                  objectFit: "contain",
                  objectPosition: "top",
                  padding: 20,
                }}
              >
                <img src={Logo} alt="Sacred Trails Home-stay" width={"100%"} />
              </div>
            </Link>
          </Box>

          <Box
            sx={{
              display: { xs: "none", sm: "block" },
            }}
          >
            {items.map((item) => {
              let hash_link = item.to;
              const words = hash_link.split("");
              return (
                <Button key={item.key} sx={{ color: "black" }}>
                  {words[1] === "#" ? (
                    <HashLink
                      to={item.to}
                      style={{ textDecoration: "none", color: "inherit" }}
                      onClick={scrollToTop}
                    >
                      {item.name}
                    </HashLink>
                  ) : (
                    <Link
                      to={item.to}
                      style={{ textDecoration: "none", color: "inherit" }}
                      onClick={scrollToTop}
                    >
                      {item.name}
                    </Link>
                  )}
                </Button>
              );
            })}
          </Box>
        </Toolbar>
      </AppBar>

      <nav>
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
      </nav>
    </Box>
  );
};

export default Navbar;
