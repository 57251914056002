import { useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import emailjs from "@emailjs/browser";
import dayjs from "dayjs";

import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  Box,
  Checkbox,
  Container,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import { notify } from "src/utils/ToastMessage";

const BookingForm = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const formRef = useRef(null);
  const totalRooms = [1, 2, 3, 4];

  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    full_name: "",
    email: "",
    phone: "",
    country: "",
    pax: "1",
    room: "",
    airport_pickup: "yes",
    flight_number: "",
  });

  const queryParams = new URLSearchParams(location.search);
  const encodedData = queryParams.get("data");
  const decodedData = encodedData
    ? JSON.parse(decodeURIComponent(encodedData))
    : null;

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (event) => {
    setLoading(true);

    if (event) {
      event.preventDefault();
    }

    const formElement = formRef.current as HTMLFormElement | null;

    if (formElement) {
      emailjs
        .sendForm(
          "service_xnmuual",
          "template_gsnslhs",
          formElement,
          "W0Csfpl1t1Ks156GZ"
        )
        .then(
          (result) => {
            console.log(result.text);
            notify("Your booking has been send", "success");
            formElement.reset();
            setLoading(false);
            navigate(`/#room`);
          },
          (error) => {
            console.log(error.text);
            setLoading(false);
          }
        );
    }
  };

  return (
    <Container
      sx={{
        marginTop: 19,
        marginBottom: 7,
      }}
    >
      <Typography
        variant="h6"
        sx={{
          margin: "20px 0",
          fontSize: 24,
        }}
      >
        Room Enquiry Form
      </Typography>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: ["1fr", "repeat(2, 1fr)"],
          gap: "40px",
        }}
      >
        <Box
          sx={{
            justifyContent: "center",
          }}
        >
          <img
            src={decodedData.mainPhoto}
            width={"100%"}
            height={350}
            alt="Room"
            style={{
              objectFit: "cover",
            }}
          />
          <Typography
            variant="h2"
            component={"h2"}
            sx={{
              color: "#152C5B",
              fontSize: "20px",
              fontWeight: 500,
              marginTop: "10px",
            }}
          >
            {decodedData.roomName} @{" "}
            <span
              style={{
                fontWeight: "bold",
              }}
            >
              {formData.pax === "2"
                ? Number(decodedData.roomPrice + 10).toFixed(2)
                : decodedData.roomPrice}{" "}
              Dollar
            </span>{" "}
            for {formData.pax} pax with{" "}
            {decodedData.isBreakfast
              ? "included breakfast"
              : "excluded breakfast"}{" "}
            per night
          </Typography>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Box>
              <Typography
                variant="body1"
                component={"p"}
                sx={{
                  color: "#152C5B",
                  fontSize: "16px",
                  fontWeight: 400,
                }}
              >
                {decodedData.isBreakfast
                  ? "Included Breakfast"
                  : "Excluded Breakfast"}
              </Typography>
            </Box>
            <Checkbox
              checked={decodedData.isBreakfast}
              inputProps={{ "aria-label": "controlled" }}
              icon={<FavoriteBorderIcon />}
              checkedIcon={<FavoriteIcon />}
            />
          </Box>
          <Box
            sx={{
              marginTop: "20px",
            }}
          >
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: ["column", "row"],
                  gap: "10px",
                }}
              >
                <DatePicker
                  defaultValue={dayjs(decodedData.checkIn)}
                  label="Check-In"
                  sx={{
                    marginRight: [0, "10px"],
                  }}
                  disabled={true}
                />
                <DatePicker
                  defaultValue={dayjs(decodedData.checkOut)}
                  label="Check-Out"
                  disabled={true}
                />
              </Box>
            </LocalizationProvider>
          </Box>
        </Box>

        <Box>
          <form ref={formRef} onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12} sm={12}>
                <TextField
                  label="Full Name"
                  name="full_name"
                  value={formData.full_name}
                  onChange={handleChange}
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  required
                />
              </Grid>
              <Grid item xs={12} md={6} sm={6}>
                <TextField
                  label="Email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  required
                  InputProps={{
                    inputProps: {
                      pattern:
                        "^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$",
                      title: "Invalid email address",
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6} sm={6}>
                <TextField
                  label="Phone No."
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  required
                  InputProps={{
                    inputProps: {
                      pattern: "^[0-9]{10,14}$",
                      title: "Invalid phone number format",
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} md={12} sm={12}>
                <TextField
                  label="Country"
                  name="country"
                  value={formData.country}
                  onChange={handleChange}
                  fullWidth
                  margin="normal"
                  required
                  variant="outlined"
                />
              </Grid>

              <Grid item xs={12} md={6} sm={6}>
                <TextField
                  name={"pax"}
                  label={"Pax"}
                  defaultValue={"1"}
                  select
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  required={true}
                  onChange={handleChange}
                >
                  <MenuItem value={"1"}>1</MenuItem>
                  <MenuItem value={"2"}>2</MenuItem>
                </TextField>
              </Grid>

              <Grid item xs={12} md={6} sm={6}>
                <TextField
                  name={"room"}
                  label={"Room"}
                  defaultValue={"1"}
                  select
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  required={true}
                  onChange={handleChange}
                >
                  {decodedData.roomName.toLowerCase() ===
                  "Twin Bed Room".toLowerCase()
                    ? totalRooms
                        .slice(0, 2)
                        .map((room) => <MenuItem value={room}>{room}</MenuItem>)
                    : totalRooms.map((room) => (
                        <MenuItem value={room}>{room}</MenuItem>
                      ))}
                </TextField>
              </Grid>

              <Grid item xs={12} md={6} sm={6}>
                <TextField
                  name={"airport_pickup"}
                  label={"Airport Pickup"}
                  defaultValue={"yes"}
                  select
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  required={true}
                  onChange={handleChange}
                >
                  <MenuItem value={"yes"}>Yes</MenuItem>
                  <MenuItem value={"no"}>No</MenuItem>
                </TextField>
              </Grid>
              {formData.airport_pickup === "yes" && (
                <Grid item xs={12} md={6} sm={6}>
                  <TextField
                    label="Flight Number"
                    name="flight_number"
                    value={formData.flight_number}
                    onChange={handleChange}
                    fullWidth
                    required
                    margin="normal"
                    variant="outlined"
                  />
                </Grid>
              )}

              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Box
                  sx={{
                    display: "none",
                  }}
                >
                  <DatePicker
                    defaultValue={dayjs(decodedData.checkIn)}
                    label="Check-In"
                    sx={{
                      marginRight: [0, "10px"],
                    }}
                    slotProps={{
                      textField: {
                        required: true,
                        name: "check_in",
                      },
                    }}
                  />
                  <DatePicker
                    defaultValue={dayjs(decodedData.checkOut)}
                    label="Check-Out"
                    slotProps={{
                      textField: {
                        required: true,
                        name: "check_out",
                      },
                    }}
                  />
                </Box>
              </LocalizationProvider>

              <TextField
                style={{
                  display: "none",
                }}
                key={"room_name"}
                name={"room_name"}
                label={"room_name"}
                value={` ${decodedData.roomName} @
     
              ${
                formData.pax === "2"
                  ? Number(decodedData.roomPrice + 10).toFixed(2)
                  : decodedData.roomPrice
              }
              Dollar
            for ${formData.pax} pax with
            ${
              decodedData.isBreakfast
                ? "included breakfast"
                : "excluded breakfast"
            }
            per night`}
                type={"text"}
                fullWidth
                margin="normal"
                variant="outlined"
              />

              <LoadingButton
                type="submit"
                variant="contained"
                color="primary"
                sx={{
                  margin: "0 auto",
                  marginTop: "20px",
                  borderRadius: 1,
                  width: "200px",
                  background: "#2D9596",
                  boxShadow: "0px 8px 15px 0px rgba(50, 82, 223, 0.30)",
                  maxWidth: ["100%", "100%", "210px"],
                  minHeight: "50px",
                  alignSelf: ["center", "center", "flex-end"],
                  marginBottom: ["20px", "56px"],
                  "&:hover": {
                    background: "#34A0A4", // Change this to the color you want on hover
                  },
                }}
                loading={loading}
              >
                Inquiry Room
              </LoadingButton>
            </Grid>
          </form>
        </Box>
      </Box>
    </Container>
  );
};
export default BookingForm;
