import { Suspense } from "react";
import { Route, Routes } from "react-router-dom";

import { routes } from "./routes.item";

const MainRoutes = () => (
  <Routes>
    {routes.map((route, index) => (
      <Route
        key={index}
        path={route?.path}
        element={
          <Suspense fallback={<div>{route?.loading}</div>}>
            {route?.component}
          </Suspense>
        }
      />
    ))}
  </Routes>
);

export default MainRoutes;
