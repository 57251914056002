import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { Box, Button, Container, Typography } from "@mui/material";
import { BackgroundIcon } from "src/assets";
import { eventsData } from "src/utils/review-images";

import { useMediaQuery, useTheme } from "@material-ui/core";
import { useState } from "react";
import ModalPopUpDetail from "../modalPopUpDetails";

const Event = () => {
  const [open, setOpen] = useState(false);
  const [showDirection, setShowDirection] = useState(false);
  const [singleData, setSingleData] = useState({});
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"), {
    noSsr: true,
  });

  const handleOpen = (event) => {
    setOpen(true);
    setSingleData(event);
  };

  const handleClose = () => {
    setOpen(false);
    setShowDirection(false);
  };

  return (
    <Box sx={{ position: "relative", marginTop: [7, 14] }}>
      <Box
        sx={{
          position: "absolute",
          top: "0px",
          width: "100%",
          transform: "rotate(180deg)",
        }}
      >
        <img
          src={BackgroundIcon}
          alt="BackgroundIcon"
          width={"100%"}
          height={"100%"}
        />
      </Box>
      <Container sx={{ zIndex: 10, position: "relative", paddingTop: [7, 14] }}>
        <Box sx={{ textAlign: "center" }}>
          <Typography
            variant="h2"
            component={"h2"}
            sx={{
              color: "#000",
              fontSize: ["35px", "56px"],
              fontWeight: [500, 700],
            }}
          >
            Activities to Enjoy During Your Stay
          </Typography>
          <Typography variant="h6" component={"h6"} color={"#2D9596"}>
            Explore Nearby Attractions Close to Our Home Stay
          </Typography>
        </Box>

        {eventsData.map((event) =>
          event.isEvent || isMobile ? (
            <Box
              sx={{
                display: "flex",
                gap: [1, 10],
                marginTop: [5, 10],
                flexDirection: ["column", "row"],
              }}
            >
              <Box
                sx={{
                  maxHeight: 500,
                  maxWidth: 500,
                  overflow: "hidden",
                  borderRadius: 10,
                }}
              >
                <img
                  src={event.imageItem}
                  width={"100%"}
                  height={"100%"}
                  alt="background images"
                  style={{
                    objectFit: "cover",
                  }}
                />
              </Box>
              <Box sx={{ padding: [2, 5], marginTop: [1, 4] }}>
                <Typography
                  variant="body1"
                  component={"p"}
                  sx={{
                    color: "var(--Rectangle-807, #1A759F)",
                    fontSize: "16px",
                    fontWeight: 400,
                  }}
                >
                  {event.eventTitle}
                </Typography>
                <Typography
                  variant="h2"
                  component={"h2"}
                  sx={{
                    color: "#000",
                    fontSize: "36px",
                    fontWeight: 500,
                  }}
                >
                  {event.eventSubTitle}
                </Typography>
                <Typography
                  variant="body1"
                  component={"p"}
                  sx={{
                    color: "#525252",
                    fontSize: "16px",
                    fontWeight: 400,
                    marginTop: 4,
                  }}
                >
                  {event.eventDescription}
                </Typography>
                <Typography
                  variant="body1"
                  component={"p"}
                  sx={{
                    color: "var(--Rectangle-807, #1A759F)",
                    fontSize: "16px",
                    fontWeight: 700,
                    marginTop: 2,
                    display: "flex",
                    flexDirection: ["column", "row"],
                    gap: 1,
                    alignItems: "center",
                  }}
                >
                  <CalendarMonthIcon /> {event.eventDate}
                </Typography>
                <Button
                  variant="contained"
                  sx={{
                    boxShadow: "0px 8px 15px 0px rgba(50, 82, 223, 0.30)",
                    maxWidth: ["100%", "250px"],
                    width: "100%",
                    minHeight: "50px",
                    marginTop: 2,
                    alignSelf: ["center", "flex-end"],
                    borderRadius: "64px",
                    background: "var(--Rectangle-805, #34A0A4)",
                    padding: "5px 30px",
                    "&:hover": {
                      background: "#34A0A4", // Change this to the color you want on hover
                    },
                  }}
                  onClick={() => handleOpen(event)}
                >
                  Learn More
                </Button>
              </Box>
            </Box>
          ) : (
            <Box
              sx={{
                display: "flex",
                flexDirection: ["column", "row"],
                gap: [1, 10],
                marginTop: [0, 10],
              }}
            >
              <Box sx={{ padding: [2, 5], marginTop: [1, 4] }}>
                <Typography
                  variant="body1"
                  component={"p"}
                  sx={{
                    color: "var(--Rectangle-807, #1A759F)",
                    fontSize: "16px",
                    fontWeight: 400,
                  }}
                >
                  {event.eventTitle}
                </Typography>
                <Typography
                  variant="h2"
                  component={"h2"}
                  sx={{
                    color: "#000",
                    fontSize: "36px",
                    fontWeight: 500,
                  }}
                >
                  {event.eventSubTitle}
                </Typography>
                <Typography
                  variant="body1"
                  component={"p"}
                  sx={{
                    color: "#525252",
                    fontSize: "16px",
                    fontWeight: 400,
                    marginTop: 4,
                  }}
                >
                  {event.eventDescription}
                </Typography>
                <Typography
                  variant="body1"
                  component={"p"}
                  sx={{
                    color: "var(--Rectangle-807, #1A759F)",
                    fontSize: "16px",
                    fontWeight: 700,
                    marginTop: 2,
                    display: "flex",
                    flexDirection: ["column", "row"],
                    gap: 1,
                    alignItems: "center",
                  }}
                >
                  <CalendarMonthIcon /> {event.eventDate}
                </Typography>
                <Button
                  variant="contained"
                  sx={{
                    boxShadow: "0px 8px 15px 0px rgba(50, 82, 223, 0.30)",
                    maxWidth: ["100%", "250px"],
                    width: "100%",
                    minHeight: "50px",
                    marginTop: 2,
                    alignSelf: ["center", "flex-end"],
                    borderRadius: "64px",
                    background: "var(--Rectangle-805, #34A0A4)",
                    padding: "5px 30px",
                    "&:hover": {
                      background: "#34A0A4", // Change this to the color you want on hover
                    },
                  }}
                  onClick={() => handleOpen(event)}
                >
                  Learn More
                </Button>
              </Box>
              <Box
                sx={{
                  maxHeight: 500,
                  maxWidth: 500,
                  overflow: "hidden",
                  borderRadius: 10,
                }}
              >
                <img
                  src={event.imageItem}
                  width={"100%"}
                  height={"100%"}
                  alt="background images"
                />
              </Box>
            </Box>
          )
        )}
      </Container>
      <ModalPopUpDetail
        open={open}
        handleClose={handleClose}
        setShowDirection={setShowDirection}
        showDirection={showDirection}
        data={singleData}
      />
      ;
    </Box>
  );
};

export default Event;
