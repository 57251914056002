import { Box, Typography } from "@mui/material";
import { whyUsData } from "src/utils/review-images";

const WhyUs = () => {
  return (
    <Box
      sx={{
        position: "relative",
        zIndex: 10,
        marginTop: [7, 14],
      }}
    >
      <Box sx={{ marginBottom: [4, 8], textAlign: "center" }}>
        <Typography
          variant="h2"
          component={"h2"}
          sx={{
            fontSize: ["35px", "56px"],
            fontWeight: [500, 700],
          }}
        >
          Why at Sacred Trails
        </Typography>
        <Typography variant="h6" component={"h6"} color={"#2D9596"}>
          Unique Aspects that Enhance Our Hospitality
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          flexDirection: ["column", "row"],
          gap: 5,
        }}
      >
        {whyUsData.map((el, index) => (
          <Box
            sx={{
              flex: "0 0 calc(31% - 20px)",
              marginTop: [1, 3],
              boxShadow: "0 11px 46px 0 rgba(0,0,0,.16)",
              padding: 2,
              borderRadius: 3,
            }}
            key={index}
          >
            <Box
              sx={{
                padding: 2,
                border: "2px solid #2D9596",
                maxWidth: "70px",
                textAlign: "center",
                borderRadius: "50px",
              }}
            >
              {el.icon}
            </Box>
            <Typography
              variant="h5"
              component={"h5"}
              sx={{
                marginTop: 3,
                marginBottom: 3,
              }}
            >
              {el.title}
            </Typography>
            <Typography variant="body1" component={"p"}>
              {el.describe}
            </Typography>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default WhyUs;
