import { Box, Button, Typography } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { RoomDetailsData } from "src/utils/review-images";

const RoomBanner = (props) => {
  const navigate = useNavigate();
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };
  const handleRoom = (id) => {
    navigate(`/room/detail/${id}`);
  };

  return (
    <Box
      sx={{
        marginTop: [7, 14],
        zIndex: 10,
        position: "relative",
        padding: "0px 0px 100px 0px",
      }}
    >
      <Box sx={{ marginBottom: [4, 8], textAlign: "center" }}>
        <Typography
          variant="h2"
          component={"h2"}
          sx={{
            fontSize: ["35px", "56px"],
            fontWeight: [500, 700],
          }}
        >
          {props.title ? props.title : "Rooms at Sacred Trails"}
        </Typography>
        <Typography variant="h6" component={"h6"} color={"#2D9596"}>
          Where the Hospitality of Kathmandu Lives
        </Typography>
      </Box>

      <Box sx={{ display: "flex", gap: 5, flexDirection: ["column", "row"] }}>
        {RoomDetailsData.slice(0, 3).map((room) => (
          <Link
            to={`/room/detail/${room.id}`}
            style={{
              textDecoration: "none",
              color: "inherit",
            }}
            onClick={scrollToTop}
          >
            <Box key={room.id}>
              <Box
                sx={{
                  maxWidth: "450px",
                  maxHeight: "400px",
                  width: "100%",
                  height: "100%",
                }}
              >
                <img
                  src={room.mainImage}
                  width={"100%"}
                  height={"100%"}
                  alt={room.roomName}
                  style={{
                    borderRadius: "10px",
                    objectFit: "cover",
                    objectPosition: "center top",
                    overflow: "hidden",
                  }}
                />
              </Box>
              <Typography variant="h5" component={"h5"}>
                {room.roomName}
              </Typography>
              <Typography variant="body1" component={"p"}>
                Attached Bathroom
              </Typography>
              <Typography variant="body1" component={"p"}>
                <span
                  style={{
                    fontWeight: "bold",
                  }}
                >
                  ${room.roomPriceWithBreakfast}
                </span>
                - Breakfast included
              </Typography>
            </Box>
            <Button
              variant="contained"
              sx={{
                boxShadow: "0px 8px 15px 0px rgba(50, 82, 223, 0.30)",
                maxWidth: ["100%", "220px"],
                width: "100%",
                minHeight: "50px",
                marginTop: 2,
                alignSelf: ["center", "flex-end"],
                borderRadius: "10px",
                background: "var(--Rectangle-805, #34A0A4)",
                padding: "5px 30px",
                "&:hover": {
                  background: "#34A0A4", // Change this to the color you want on hover
                },
              }}
              onClick={() => handleRoom(room.id)}
            >
              Explore the room
            </Button>
          </Link>
        ))}
      </Box>
    </Box>
  );
};

export default RoomBanner;
