/* eslint-disable comma-dangle */
/* eslint-disable function-paren-newline */

import AboutUs from "src/pages/AboutPage";
import RoomDetail from "src/pages/RoomDetailPage";
import BookingForm from "src/pages/bookingForm";
import HomePage from "src/pages/homePage";

export const routes = [
  {
    path: "/",
    component: <HomePage />,
    loading: "Loading...",
  },
  {
    path: "/about",
    component: <AboutUs />,
    loading: "Loading...",
  },
  {
    path: "/room/detail/:id",
    component: <RoomDetail />,
    loading: "Loading...",
  },
  {
    path: "/room/booking",
    component: <BookingForm />,
    loading: "Loading...",
  },
];
